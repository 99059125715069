import Cookies from 'js-cookie';
import Session from '../models/session';

const ENVIRONMENT = process.env?.REACT_APP_ENV;

const getSession = (): Session | null => {
  const userId = Cookies.get(`${ENVIRONMENT}.userId`) ?? null;
  const loginToken = Cookies.get(`${ENVIRONMENT}.loginToken`) ?? null;
  const scope = Cookies.get(`${ENVIRONMENT}.scope`) ?? null;

  if (userId && loginToken) {
    const session: Session = {
      userId: userId,
      loginToken: loginToken,
      scope: scope, 
    };
    return session;
  } else {
    return null;
  }
};

const removeSession = () => {
  const userId = Cookies.get(`${ENVIRONMENT}.userId`) ?? null;
  const loginToken = Cookies.get(`${ENVIRONMENT}.loginToken`) ?? null;

  if (userId !== null) {
    Cookies.remove(`${ENVIRONMENT}.userId`);
  }

  if (loginToken !== null) {
    Cookies.remove(`${ENVIRONMENT}.loginToken`);
  }
};

const SessionService = {
  getSession,
  removeSession,
};

export default SessionService;
