import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import store from './store';
import Routes from './routes';
import Theme from './config/theme';

const App = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
};
export default App;
