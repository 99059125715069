/* eslint-disable @typescript-eslint/no-unused-vars */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import DisplayTemplateContext from '../templates/DisplayNew/contexts/displayTemplate.context';
import ClientClock from './ClientClock';

const Header = (): React.ReactElement => {
  const displayTemplateContext = React.useContext(DisplayTemplateContext);

  return (
    <AppBar
      position="fixed"
      color={process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'primary' : 'secondary'}
    >
      <Toolbar>
        {/* Left area */}
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 2 }}>

          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{ textDecoration: 'none', color: 'white' }}
            noWrap
          >
            FOODOLOGY
          </Typography>
        </Box>

        {/* Center area */}
        <Box sx={{ textTransform: 'uppercase', flex: 4, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {displayTemplateContext.toolbarTitle}
          </Typography>
        </Box>

        {/* Right area */}
        <Box sx={{ flex: 2, display: 'flex', justifyContent: 'end' }}>
          <Box
            sx={{
              display: 'table',
              textAlign: 'center',
              marginLeft: '32px',
              marginRight: '32px',
            }}
          >
            <ClientClock />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
