import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Session from './models/session';
import User from './models/user';

export interface SessionState {
  user: User | null;
  session: Session | null;
}

const initialState: SessionState = {
  user: null,
  session: null,
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUser: (state, user: PayloadAction<User>) => {
      state.user = user.payload;
    },
    setSession: (state, session: PayloadAction<Session>) => {
      state.session = session.payload;
    },
  },
});

export const { setUser, setSession } = session.actions;
export default session.reducer;
