import { AlertColor, SnackbarOrigin } from '@mui/material';
import React from 'react';
import { useAudio } from 'react-use';
import textToSpeech from '../../../../utils/textToSpeech';

type ToolbarTitle = string | null;
type SetToolbarTitle = (toolbarTitle: ToolbarTitle) => void;

interface Alert {
  show: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
}

interface DisplayTemplateContextState {
  toolbarTitle?: ToolbarTitle;
  setToolbarTitle?: SetToolbarTitle;
  alert: Alert;
  showAlert: (
    title?: string | null,
    message?: string | null,
    duration?: number | null,
    audioOn?: boolean | null,
    severity?: AlertColor | null,
    position?: SnackbarOrigin | null,
  ) => void;
  hideAlert?: () => void;
}

const initialState: DisplayTemplateContextState = {
  alert: { show: false },
  showAlert: () => {},
};

const DisplayTemplateContext = React.createContext(initialState);

interface DisplayTemplateProviderProps {
  children: React.ReactElement;
}

export const DisplayTemplateProvider = (
  props: DisplayTemplateProviderProps,
): React.ReactElement => {
  const { children } = props;

  const [toolbarTitle, setToolbarTitle] = React.useState<ToolbarTitle | null>();
  const [alert, setAlert] = React.useState<Alert>({ show: false });

  const [audio, , controls] = useAudio({
    src: 'https://kitchen-os-assets.s3.amazonaws.com/audios/new-order.mp3',
  });

  const showAlert = (
    title?: string | null,
    message?: string | null,
    duration?: number | null,
    audioOn?: boolean | null,
    severity?: AlertColor | null,
    position?: SnackbarOrigin | null,
  ) => {
    if (audioOn && title) {
      controls.seek(0);
      controls.play();
      textToSpeech(title, 600);
    }
    setAlert({
      show: true,
      title,
      message,
      duration,
      severity: severity ?? undefined,
      position: position ?? undefined,
    });
  };

  const hideAlert = () => {
    setAlert({ show: false });
  };

  return (
    <DisplayTemplateContext.Provider
      value={{
        toolbarTitle,
        setToolbarTitle,
        alert,
        showAlert,
        hideAlert,
      }}
    >
      {children}
      {audio}
    </DisplayTemplateContext.Provider>
  );
};

export default DisplayTemplateContext;
