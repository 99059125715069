import axios from 'axios';
import Session from '../models/session';
import User from '../models/user';
import UserResponse from '../models/user-response';

const getUser = async (session: Session): Promise<User> => {
  return await axios({
    url: `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/api/userInfo`,
    method: 'GET',
    headers: {
      'x-user-id': session.userId,
      'x-auth-token': session.loginToken,
    },
  }).then<User>((response) => {
    const data: UserResponse = response.data;
    const kitchenId = data.profile.kitchen?.kitchenId ?? '';
    const country = data.profile.country ?? '';
    const user: User = {
      username: data.username,
      name: data.profile.name,
      rol: data.rol,
      kitchenId: kitchenId,
      country: country,
    };

    return user;
  });
};

const UserService = {
  getUser,
};
export default UserService;
