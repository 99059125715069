import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Session from './components/Session';
import NotFoundPageView from '../shared/pages/NotFoundNew/notFoundPage.view';
import DisplayTemplate from '../shared/templates/DisplayNew';
import Spinner from '../shared/components/Spinner';

const WaitingTimeContainer = lazy(() => import('../pages/dashboard/waitingTime.container'));
const Infull = lazy(() => import('../pages/Infull'));

const routes = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <DisplayTemplate>
        <Session>
          <Routes>
            <Route
              path="/kitchen/:kitchenId"
              element={
                <Suspense fallback={<Spinner />}>
                  <WaitingTimeContainer />
                </Suspense>
              }
            ></Route>
            <Route
              path="/infull/:kitchenId"
              element={
                <Suspense fallback={<Spinner />}>
                  <Infull />
                </Suspense>
              }
            ></Route>
            <Route path="*" element={<NotFoundPageView />} />
          </Routes>
        </Session>
      </DisplayTemplate>
    </BrowserRouter>
  );
};

export default routes;
