import amplitude from 'amplitude-js';

export enum AmplitudeEvent {
  CLICK_VIEW_IF_LARGE = 'Clic a visualiza tus quejas en vista OT',
  CLICK_VIEW_IF_SMALL = 'Flecha a Vista IF',
}

const init = (): void => {
  amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_APY_KEY}`);
};

const logEvent = (event: AmplitudeEvent, eventProperties?: Record<string, unknown>): void => {
  amplitude.getInstance().logEvent(event, eventProperties);
};

const Analytics = { init, logEvent };

export default Analytics;
